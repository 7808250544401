import './App.css';
import './WhiteLabel.css';
import './responsive.css'
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRoute } from "./components/PrivateRoute";
import { loadUser } from "./actions/authAction";
import { removeAlert } from './actions/alert';

import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Profile from "./components/user/Profile";
import Privacy from "./components/user/Privacy";
import AccountManagement from "./components/user/AccountManagement";
import Upgrades from "./components/user/Upgrades";
import HelpSupport from "./components/support/HelpSupport";
import Training from "./components/support/Training";
import TrainingArticles from "./components/support/TrainingArticles";
import MasterLogin from "./components/MasterLogin";
import Integration from "./components/integration/Integration";
import Reseller from "./components/user/Reseller";
import Editor from './components/editor/Editor';
import CreateTopic from './components/createCourse/CreateTopic';
import CreateChapter from './components/createCourse/CreateChapter';
import Chapters from './components/createCourse/Chapters';
import WriteChapters from './components/createCourse/WriteChapters';
import Courses from './components/project/Courses';
import ReelMerge from './components/project/ReelMerge';
import WhiteLabelTabs from './components/WhiteLabel/WhiteLabelTabs';



function App() {

    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert)
    const auth = useSelector(state => state.auth)
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        dispatch(loadUser())
    }, [])

    useEffect(() => {
        if (alert !== undefined) {
            if (alert.message !== "") {
                setTimeout(() => {
                    dispatch(removeAlert())
                }, 5000);
            }
        }
    }, [alert.message])

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={ForgetPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/master-login" component={MasterLogin} />

                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/courses" component={Courses} />
                    <PrivateRoute exact path="/privacy" component={Privacy} />
                    <PrivateRoute exact path="/account-management" component={AccountManagement} />
                    <PrivateRoute exact path="/reseller" component={Reseller} />
                    <PrivateRoute exact path="/upgrades" component={Upgrades} />
                    <PrivateRoute exact path="/white-label" component={WhiteLabelTabs} />
                    <PrivateRoute exact path="/help-and-support" component={HelpSupport} />
                    <PrivateRoute exact path="/training" component={Training} />
                    <PrivateRoute exact path="/support-article" component={TrainingArticles} />
                    <PrivateRoute exact path="/integration" component={Integration} />
                    <PrivateRoute exact path="/editor" component={Editor} />
                    <PrivateRoute exact path="/create-topic" component={CreateTopic} />
                    <PrivateRoute exact path="/create-chapter" component={CreateChapter} />
                    <PrivateRoute exact path="/chapters" component={Chapters} />
                    <PrivateRoute exact path="/write-chapters" component={WriteChapters} />
                    {memberShip.includes("professional") ?
                        <PrivateRoute exact path="/reel-merge" component={ReelMerge} /> : ''}
                    <Route path="*" component={Login} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
