import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../images/modal-close.png';
import useRecorder from "../MyRecording";
import { IoMdMic, IoMdPause, IoMdPlay, IoMdSquare } from "react-icons/io"
import { setAlert } from '../../actions/alert';
import { useDispatch, useSelector } from 'react-redux';
import { commonAxios } from '../../global/CommonAxios';

let newIntervalId, music = false
const RecordAudioModal = ({ show3, handleClose3, handleShow4, handleClose4, handleShow5, setPercent, setUploadData }) => {

    let [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording] = useRecorder();

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [play, setPlay] = useState(false)
    const [second, setSecond] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        else if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        else if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        else if (type === "stop") {
            if (status.start || status.pause || status.resume) {
                stopRecording()
                setT(false)
                clearInterval(newIntervalId);
                let totalTime = timer.h * 3600 + timer.m * 60 + second
                setAudioDuration(totalTime)
                setSecond(0)
                setTimer({
                    ...timer,
                    h: 0,
                    m: 0
                })
                return;
            }
        }

    };

    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])


    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    const handlePlay = () => {
        if (audioURL) {
            const file = URL.createObjectURL(audioURL)
            music = new Audio(file)
            music.play()
            setPlay(true)
        }
        else {
            dispatch(setAlert("No recorded audio found", "danger"))
        }
    }

    // const handlePause = () => {
    //     music.pause()
    //     setPlay(false)

    // }


    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])

    const handleSelectRecording = () => {

        let config = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setPercent(percent);
            }
            ,
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.token
            },
        };

        const file = new File([audioURL], "audio.webm", { type: "audio/webm" });

        const formData = new FormData();
        formData.append('file', file)
        formData.append('upload_type', "transcribe")
        formData.append('user_id', auth.user.id)
        if (audioURL !== "") {
            handleClose3()
            handleShow4()
            commonAxios("file-upload", formData, dispatch, config)
                .then((res) => {
                    if (res.status) {
                        setUploadData(res.data.path)
                        handleClose4()
                        handleShow5()
                    } else {
                        dispatch(setAlert(res.msg, "danger"))
                    }
                    setPercent(0)
                }).catch((err) => {
                    setPercent(0)
                })
        } else {
            dispatch(setAlert("Please record an audio first", "danger"))
        }
    }





    return (
        <Modal className="VideoModal small white" show={show3} onHide={handleClose3} centered>
            <Modal.Body className="audioModal text-center">
                <div onClick={handleClose3} className="vidClose" style={{top:"5px", right:"5px"}}><img src={modalCloseIcon} /></div>
                <h4 id="rec-title" class="modal-title text-white mb-2">Record Audio</h4>

                <span id="time-display" class="mb-2 text-white">{timer.h < 10 ? "0" : ""}{timer.h}:{timer.m < 10 ? "0" : ""}{timer.m}:{second < 10 ? "0" : ""}{second}</span>
                <div className='d-flex gap-5'>
                    {status.start ?
                        !status.pause ?
                            <button
                                id="record"
                                className="bg-white d-flex w-100 mx-auto rounded-circle"
                                onClick={() => handleClick("pause")}
                                title="Pause"
                            >
                                <IoMdPause
                                    size={28}
                                />
                            </button>
                            :
                            <button
                                id="record"
                                className="bg-white d-flex w-100 mx-auto rounded-circle"
                                onClick={() => handleClick("resume")}
                                title="Resume"
                            >
                                <IoMdPlay
                                    size={28}
                                />
                            </button>
                        :
                        <button
                            id="record"
                            className="bg-white d-flex rounded-circle"
                            onClick={() => handleClick("start")}
                            title="Start"
                        >
                            <IoMdMic
                                size={28}
                            />
                        </button>
                    }
                    <button
                        onClick={() => handleClick("stop")}
                        id="record"
                        className="bg-white d-flex rounded-circle"
                        title='Stop'
                    >
                        <IoMdSquare
                            size={25}
                            color={status.start ? "red" : ""}
                        />
                    </button>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer border-top-0 justify-content-center bg-white pt-4 pb-4">
                <button
                    type="submit"
                    className="inpBtn auto"
                    onClick={handlePlay}
                    style={{maxWidth:"120px"}}
                >
                    {play ? "Playing" : "Play"}
                </button>
                <button
                    type="submit"
                    className="inpBtn auto"
                    onClick={handleSelectRecording}
                    style={{maxWidth:"120px"}}
                    title="upload to transcribe"
                >
                    Done
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default RecordAudioModal;