import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
// import logo from "../../images/LOGO.png"
import loginBg from "../../images/BG.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../actions/authAction";
import Alert from "../Alert";
import { Helmet } from "react-helmet";

import logo from '../../images/LOGO.png';
import TitleBar from "../TitleBar";

const ForgetPassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [emailDetails, setEmailDetails] = useState({
        email: ''
    })

    const onInputChange = (e) => {
        setEmailDetails({ ...emailDetails, email: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(forgetPassword(emailDetails, setLoader));
    }

    return (
        <>

            <TitleBar title="Forgot Password" />
            <Alert />

            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        <img src={logo} />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2>Welcome To <span>CourseReel AI!</span></h2>
                                <p>Create Video Courses, VSL and more easily using <br /> moderner drag-n-drop based timeline editor</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Enter </span> Your Email Address</h2>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input type="email" className="form-control" placeholder="Enter Email"
                                                required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Submit
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Remember Login ? <Link to="/login" className="text-center "> Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginFooter />

        </>
    )
}

export default ForgetPassword;