import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AddAccount from "./AddAccount";
import TitleBar from "../TitleBar";

const AccountManagement = () => {
    return(
        <>
            <TitleBar title="Account Management"/>
            <Navbar/>

            <section className="siteWrap">
                <div className="siteWrap-in pt-3">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                               <AddAccount/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/> 
        </>
    )
}

export default AccountManagement;