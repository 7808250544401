import React from 'react'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import WhiteLabel from './WhiteLabel';
import WhiteLabelMember from './WhiteLabelMember';
import WhiteLabelUser from './WhiteLabelUser';
import PaymentIntegration from './PaymentIntegration/PaymentIntegration';
import { MdOutlineInbox } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { LuFileEdit, LuUser } from "react-icons/lu";
import { RiBookmark3Line } from "react-icons/ri";
import { useSelector } from 'react-redux';
import TitleBar from '../TitleBar';
import Navbar from '../Navbar';
import Footer from '../Footer';
const WhiteLabelTabs = () => {
    return (
        <>
            <TitleBar title="White Label" />
            <Navbar />
            <div className="siteWrap">
                <div className="whiteLabel_wrapper link-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <div className="col-12 col-xl-3 mb-4 mb-xl-0">
                                            <div className="white_label_side_bg">
                                                <div className="white_label_head mb-2">
                                                    <h2> <MdOutlineInbox className='mr-2' fontSize={30} />White Label</h2>
                                                </div>
                                                <Nav variant="pills" className="flex-column" style={{ paddingLeft: '15px' }}>
                                                    <Nav.Item className='white-item'>
                                                        <Nav.Link eventKey="first" className='white-link'> <LuFileEdit className='mr-2' /> Customization</Nav.Link>
                                                    </Nav.Item >
                                                    <Nav.Item className='white-item'>
                                                        <Nav.Link eventKey="fourth" className='white-link'><GrMoney className='mr-2' />Payment Integration</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='white-item'>
                                                        <Nav.Link eventKey="second" className='white-link'> <RiBookmark3Line className='mr-2' />Membership</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='white-item'>
                                                        <Nav.Link eventKey="third" className='white-link'> <LuUser className='mr-2' />Users</Nav.Link>
                                                    </Nav.Item>

                                                </Nav>
                                            </div>
                                        </div>

                                        <div className="col-12 col-xl-9">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <WhiteLabel />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <WhiteLabelMember />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <WhiteLabelUser />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fourth">
                                                    <PaymentIntegration />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
            <Footer />

        </>
    )
}

export default WhiteLabelTabs
