import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { setAlert } from '../../actions/alert';
import { commonAxios } from '../../global/CommonAxios';
import Alert from '../Alert';
import FileCard from './FileCard';


const UploadComponent = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    const [uploadImages, setUploadImages] = useState([])
    const [uploadVideos, setUploadVideos] = useState([])
    const [dataFile, setDataFiles] = useState([])
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        }
    };

    const handleCall = (e, type, config) => {
        const formData = new FormData()
        formData.append('upload_type', type)
        formData.append('file', e.target.files[0])
        formData.append('user_id', auth.user.id)
        commonAxios("file-upload", formData, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(setAlert(res.msg, "success"))
                    fetchFiles(type)
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setPercent(0)
                setLoader(false)
            }).catch(() => {
                setPercent(0)
                setLoader(false)
            })
    }

    const onInputFile = (e) => {
        let type
        const config = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setPercent(percent);
            },
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.token
            },
        }

        if (props.type === "media") {
            let allowedSize = 5000000;
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].type !== "video/mp4") {
                    type = "images"
                }
                else {
                    type = "video"
                    allowedSize = 20000000;
                }
                if (allowedSize >= e.target.files[0].size) {
                    setLoader(true)
                    handleCall(e, type, config)
                }
                else {
                    swal("Oops!", "Max allowed size for image 5MB for video 20MB");
                }
            }
            else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
        else if (props.type === "music") {
            let allowedExt = ['audio/mpeg'];
            let allowedSize = 5000000
            type = "music"
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < allowedSize) {
                    setLoader(true)
                    handleCall(e, type, config)
                } else {
                    swal("Oops!", "Max allowed size for Music File is 5MB");
                }
            }
            else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
        else if (props.type === "voiceover") {
            let allowedExt = ['audio/mpeg'];
            let allowedSize = 5000000

            type = "voiceover"
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < allowedSize) {
                    setLoader(true)
                    handleCall(e, type, config)
                } else {
                    swal("Oops!", "Max allowed size for VoiceOver is 5MB");
                }
            }
            else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }


    }

    const fetchFiles = (type) => {
        let data = {
            type: type
        }
        commonAxios("fetch-user-uploaded-file", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    let val = res.data
                    val = val.reverse()
                    if (type === "images") {
                        setUploadImages(val)
                    }
                    else if (type === "video") {
                        setUploadVideos(val)
                    }
                    else {
                        setDataFiles(val)
                    }
                }
                else {
                    // if (uploadedImage.length === 1) {
                    //     setUploadedImage([]);
                    // }
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (props.type === "media") {
            fetchFiles("images")
            fetchFiles("video")
        } else if (props.type === "voiceover") {
            fetchFiles("voiceover")
        } else if (props.type === "music") {
            fetchFiles("music")
        }
    }, []);

    return (
        <div className='row'>
            <Alert />
            <div className='col-md-12 mt-4'>
                <div className="tabSearch">
                    <button className="demoLink btn-block mt-0 text-capitalize"><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload {props.type}</button>
                    <input className="absInp" type="file" onChange={(e) => onInputFile(e)} />
                </div>
                {
                    loader ?
                        <div className="progress mt-3">
                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#10ca9c" }} aria-valuenow={`${percent}`}
                                aria-valuemin="0" aria-valuemax="100">{percent}%

                            </div>
                        </div>
                        : ''
                }
            </div>
            <div className="innertab-scroll" id="scrollableMediaImage" style={{height:"100%"}}>
                <div className="row">
                    <div className="mediaList ">
                        <ul className='media-ul-list'>
                            <FileCard
                                type={props.type}
                                data={props.type === "media" ? uploadImages : dataFile}
                                mediaType="image"
                                tabType={props.tabType}
                                fetchFiles={fetchFiles}
                                selectedSlideIndex={props.selectedSlideIndex}
                                selectedLayerIndex={props.selectedLayerIndex}
                                slectedslide={props.slectedslide}

                            />
                            {props.type === "media" ?
                                <FileCard
                                    type={props.type}
                                    data={props.type === "media" ? uploadVideos : dataFile}
                                    mediaType="video"
                                    tabType={props.tabType}
                                    fetchFiles={fetchFiles}
                                    selectedSlideIndex={props.selectedSlideIndex}
                                    selectedLayerIndex={props.selectedLayerIndex}
                                    slectedslide={props.slectedslide}
                                /> : ''}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadComponent;
